// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beignet-js": () => import("./../../../src/pages/beignet.js" /* webpackChunkName: "component---src-pages-beignet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lang-js": () => import("./../../../src/pages/lang.js" /* webpackChunkName: "component---src-pages-lang-js" */),
  "component---src-pages-laspca-js": () => import("./../../../src/pages/laspca.js" /* webpackChunkName: "component---src-pages-laspca-js" */),
  "component---src-pages-srsa-js": () => import("./../../../src/pages/srsa.js" /* webpackChunkName: "component---src-pages-srsa-js" */),
  "component---src-pages-unim-js": () => import("./../../../src/pages/unim.js" /* webpackChunkName: "component---src-pages-unim-js" */),
  "component---src-pages-walton-js": () => import("./../../../src/pages/walton.js" /* webpackChunkName: "component---src-pages-walton-js" */)
}

